<template>
  <router-layout>
    <div class="page">
      <div class="head" ref="head">
        <van-icon name="arrow-left" @click="Back" />
         <van-search
          v-model="searchvalue"
          placeholder="请搜索房屋租售信息"
          show-action
          @input="Input"
          @search="Search"
        >
          <template #action>
            <div @click="Search">搜索</div>
          </template>
        </van-search>
      </div>
      <div class="pageMain">
        <div class="listbd">
          <van-loading
            color="#1989fa"
            type="spinner"
            class="dataLoading"
            v-if="$store.state.dataLoading"
          />
          <div v-else>
            <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
              offset="10"
              v-show="hasData"
            >
              <ul v-show="hasData">
                <li v-for="(item,index) in List" :key="index" @click="toDetail(item)">
                  <div class="listL" :style="{ 'background': 'url(' + item.image + ')'}"></div>
                  <div class="listR">
                    <div>
                      <h2>{{item.title}}</h2>
                      <p v-html="item.summary"></p>
                    </div>
                    <div class="btdiv">
                      <span class="area">{{item.time}}</span><span class="area">阅读 {{item.read}}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </van-list>
            <van-empty description="暂无内容" v-show="!hasData" />
          </div>
        </div>
      </div>
    </div>
  </router-layout>
</template>

<script>
import authMixin from "@/mixin/mixin";
export default {
  data() {
    return {
      infoType: "",
      loading: false,
      finished: false,
      page: 0,
      type: "", //房源类型
      searchvalue: "", //搜索框的值
      hasData: true,
      List: []
    };
  },
  mixins: [authMixin],
  created() {
    this.$store.commit("setDataLoading", true);
    this.getAllData();
    this.infoType = this.$route.query.infoType;
  },
  methods: {
    Back() {
      this.$router.back();
    },
    Input(){
      if(!this.searchvalue){
        this.$store.commit("setDataLoading", true);
        this.page = 0;
        this.List = [];
        this.getAllData()
      }
    },
    Search() {
      if(this.searchvalue){
        this.$store.commit("setDataLoading", true);
        this.page = 0;
        this.List = [];
        this.getAllData()
      }else{
        this.$toast({
          message: "搜索内容不能为空",
          forbidClick: true
        });
      } 
    },
    toDetail(item) {
      this.$router.push({
        path: "/HouseDetail",
        query: {
          id: item.id,
          infoType: this.infoType
        }
      });
    },
    getAllData() {
      this.$request({
        method: "get",
        url: "/cms/house/",
        params: {
          page: this.page,
          title: this.searchvalue
        }
      }).then(res => {
        this.$store.commit("setDataLoading", false);
        console.log(res);
        if (res.data && res.data.length) {
          this.hasData = true;
          res.data.forEach(item => {
            if (item.imgs) {
              //有图片
              if (item.imgs.includes(",")) {
                let objdata = `{
                "id": "${item.id}",
                "title": "${item.title}",
                "image": "${item.imgs.split(",")[0]}",
                "summary": "${item.description}",
                "time": "${item.create_time.split(" ")[0]}",
                "read": "${item.num}"
               }`;
                this.List.push(JSON.parse(objdata.replace(/[\r\n+]/g, '')))
              } else {
                let objdata = `{
                "id": "${item.id}",
                "title": "${item.title}",
                "image": "${item.imgs}",
                "summary": "${item.description}",
                "time": "${item.create_time.split(" ")[0]}",
                "read": "${item.num}"
               }`;
                this.List.push(JSON.parse(objdata.replace(/[\r\n+]/g, '')))
              }
            } else {
              //没有图片
              let objdata = `{
                "id": "${item.id}",
                "title": "${item.title}",
                "image": "${require("@/assets/images/noimg.jpg")}",
                "summary": "${item.description}",
                "time": "${item.create_time.split(" ")[0]}",
                "read": "${item.num}"
               }`;
              this.List.push(JSON.parse(objdata.replace(/[\r\n+]/g, '')))
            }
            this.loading = false;
            this.page = this.List[this.List.length - 1].id;
          });
        } else if (!res.data || (!res.data.length && this.page == 0)) {
          //暂无内容
          this.hasData = false;
        } else if (!res.data || res.data.length == 0) {
          this.finished = true;
        }
      });
    },
    onLoad() {
      //上啦加载更多
      this.getAllData();
    }
  }
};
</script>

<style scoped>
.head {
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 5;
  height: 1.43rem;
  background: #fff;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 3;
}
.head i {
  height: 1.4rem;
  font-size: 0.6rem;
  line-height: 1.4rem;
  float: left;
  margin-left: 0.15rem;
  color: #777;
}
.van-search {
  flex: 1;
  padding: 0.26667rem 0.3rem;
}
.listbd {
  margin-top: 1.45rem;
  position: relative;
  padding: 0 0.3rem 0.3rem 0.3rem;
}
.listbd li {
  padding: 0.3rem 0;
  border-bottom: 0.02667rem solid #ebedf0;
}
.listbd .listL {
  float: left;
  width: 3.5rem;
  height: 2.4rem;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}
.listbd .listR {
  margin-left: 3.9rem;
  height: 2.4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.listbd .listR h2 {
  font-size: 0.4rem;
  font-weight: normal;
  margin-bottom: 0.1rem;
  overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
}
.listbd .listR h3 {
  color: #999;
  font-size: 0.35rem;
  font-weight: normal;
  margin-bottom: 0.1rem;
  overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
}
.btdiv .area {
  font-size: 0.32rem;
  color: #777;
  margin-right: .3rem;
}
.btdiv .or {
  color: #f03535;
  font-size: 0.4rem;
  margin-right: 0.22rem;
}
.tohome {
  position: fixed;
  right: 0.1rem;
  bottom: 1rem;
  width: 1.2rem;
  height: 1.2rem;
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0.05rem 0.1rem 0.3rem rgba(0, 0, 0, 0.07);
  background-image: url(~@/assets/images/home.svg);
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: 0.6rem;
  z-index: 1;
}
.page {
  min-height: calc(100vh - 1.44rem);
}
.listR p {
  font-size: .35rem;
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>
